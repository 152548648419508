/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.neon {
	animation: neon 1s ease infinite;
	-moz-animation: neon 1s ease infinite;
	-webkit-animation: neon 1s ease infinite;
  }

  @keyframes neon {
	0%,
	100% {
	  text-shadow: 0 0 1vw #FA1C16, 0 0 3vw #FA1C16, 0 0 10vw #FA1C16, 0 0 10vw #FA1C16, 0 0 .4vw #FED128, .5vw .5vw .1vw #806914;
	  color: #FED128;
	}
	50% {
	  text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, .5vw .5vw .1vw #40340A;
	  color: #806914;
	}
}

.popup-content {
  width : 100%
} 

.react-confirm-alert-overlay {
  z-index: 9999999;
}



.text-neon {
  position: relative;
  overflow: hidden;
  filter: brightness(200%);
}

.text-text {
  background-color: black;
  color: white;
  font-size: 18px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: uppercase;
  position: relative;
  user-select: none;
}

.text-text::before {
  content: attr(data-text);
  position: absolute;
  color: white;
  filter: blur(0.5em);
  mix-blend-mode: difference;
}

.text-gradient {
  position: absolute;
  background: linear-gradient(45deg, red, gold, lightgreen, gold, red);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: multiply;
}

.text-spotlight {
  position: absolute;
  top: -100%;
  left: -100%;
  right: 0;
  bottom: 0;
  background: 
      radial-gradient(
          circle,
          white,
          transparent 25%
      ) center / 25% 25%,
      radial-gradient(
          circle,
          white,
          black 25%
      ) center / 12.5% 12.5%;
  animation: light 5s linear infinite;
  mix-blend-mode: color-dodge;
}

@keyframes light {
  to {
      transform: translate(50%, 50%);
  }
}